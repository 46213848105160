import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import other from '@/router/other'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		redirect: '/home',
	},
	{
		path: '/guiplat/shareSupply',
		name: 'shareSupply',
		meta: {
			title: '登录',
		},
		component: (resolve) =>
			require(['../views/guiplat/shareSupply'], resolve),
	},
	{
		path: '/login',
		name: 'Login',
		meta: {
			title: '登录',
		},
		component: (resolve) => require(['@/views/login/LoginView'], resolve),
	},

	{
		path: '/guiplat/orderService',
		name: 'orderService',
		meta: {
			title: '登录',
		},
		component: (resolve) =>
			require(['../views/guiplat/orderService'], resolve),
	},
	{
		path: '/guiplat/orderSS2Service',
		name: 'orderSS2Service',
		meta: {
			title: '登录',
		},
		component: (resolve) =>
			require(['../views/guiplat/orderSS2Service'], resolve),
	},

	{
		path: '/guiplat/courseDetailed',
		name: 'courseDetailed',
		meta: {
			title: '登录',
		},
		component: (resolve) =>
			require(['../views/guiplat/courseDetailed'], resolve),
	},
	{
		path: '/guiplat/orderSS4Service',
		name: 'orderSS4Service',
		meta: {
			title: '登录',
		},
		component: (resolve) =>
			require(['../views/guiplat/orderSS4Service.vue'], resolve),
	},
	{
		path: '/guiplat/chatGPT',
		name: 'chatGPT',
		meta: {
			title: 'LT',
		},
		component: (resolve) => require(['../views/guiplat/chatGPT'], resolve),
	},
	{
		path: '/service/anonymousOrderService',
		name: 'chatGPT',
		meta: {
			title: 'LT',
		},
		component: (resolve) =>
			require(['../views/service/anonymousOrderService.vue'], resolve),
	},
	{
		path: '/guiplat/anonymousOrderService',
		name: 'anonymousOrderService',
		meta: {
			title: '登录',
		},
		component: (resolve) =>
			require(['../views/guiplat/anonymousOrderService'], resolve),
	},
	{
		path: '/guiplat/anonymousSS2OrderService',
		name: 'anonymousSS2OrderService',
		meta: {
			title: '登录',
		},
		component: (resolve) =>
			require(['../views/guiplat/anonymousSS2OrderService'], resolve),
	},
	{
		path: '/forgetPass',
		name: 'Forget',
		meta: {
			title: '忘记密码',
		},
		component: (resolve) => require(['@/views/login/ForgetPass'], resolve),
	},

	{
		path: '/codeView',
		name: 'CodeView',
		meta: {
			title: '注册1',
		},
		component: (resolve) => require(['@/views/login/codeView'], resolve),
	},
	{
		path: '/register',
		name: 'Register',
		meta: {
			title: '注册',
		},
		component: (resolve) =>
			require(['@/views/login/RegisterView'], resolve),
	},

	{
		path: '/login/orderService',
		name: 'LoginOrderService',
		meta: {
			title: '订购服务',
		},
		component: (resolve) =>
			require(['@/views/login/OrderService'], resolve),
	},
	{
		path: '/',
		name: 'home',
		component: (resolve) => require(['@/views/HomeView'], resolve),
		meta: {},
		children: [
			{
				path: '/home',
				name: 'ShouYe',
				meta: {
					title: '首页',
				},
				component: (resolve) =>
					require(['@/views/ShouyeView'], resolve),
			},
			{
				path: '/courceview',
				name: 'courceview',
				meta: {
					title: '登录',
				},
				component: (resolve) =>
					require(['../views/video/courceview.vue'], resolve),
			},
			{
				path: '/userSet',
				name: 'UserSet',
				meta: {
					title: '资料设置',
				},
				component: (resolve) =>
					require(['@/views/person/UserSet'], resolve),
			},
			{
				path: '/upgradeAgent',
				name: 'UpgradeAgent',
				meta: {
					title: '升级代理',
				},
				component: (resolve) =>
					require(['@/views/person/UpgradeAgent'], resolve),
			},
			{
				path: '/upgradeAgent_sure',
				name: 'UpgradeAgent_sure',
				meta: {
					title: '升级代理',
				},
				component: (resolve) =>
					require(['@/views/person/UpgradeAgent_sure'], resolve),
			},
			{
				path: '/inviteUsers',
				name: 'InviteUsers',
				meta: {
					title: '邀请用户',
				},
				component: (resolve) =>
					require(['@/views/person/InviteUsers'], resolve),
			},
			{
				path: '/moneyDetail',
				name: 'MoneyDetail',
				meta: {
					title: '资金明细',
				},
				component: (resolve) =>
					require(['@/views/money/MoneyDetail'], resolve),
			},
			{
				path: '/orderSS2Service',
				name: 'orderSS2Service',
				meta: {
					title: '订购服务',
				},
				component: (resolve) =>
					require(['@/views/service/orderSS2Service'], resolve),
			},
			{
				path: '/orderSS3Service',
				name: 'orderSS3Service',
				meta: {
					title: '订购服务',
				},
				component: (resolve) =>
					require(['@/views/service/orderSS3Service.vue'], resolve),
			},
			{
				path: '/orderSS4Service',
				name: 'orderSS4Service',
				meta: {
					title: '订购服务',
				},
				component: (resolve) =>
					require(['@/views/service/orderSS4Service.vue'], resolve),
			},
			{
				path: '/orderService',
				name: 'OrderService',
				meta: {
					title: '订购服务',
				},
				component: (resolve) =>
					require(['@/views/service/OrderService'], resolve),
			},
			{
				path: '/accountRecharge',
				name: 'AccountRecharge',
				meta: {
					title: '账号充值',
				},
				component: (resolve) =>
					require(['@/views/money/AccountRecharge'], resolve),
			},
			{
				path: '/orderRecord',
				name: 'OrderRecord',
				meta: {
					title: '订购记录',
				},
				component: (resolve) =>
					require(['@/views/order/OrderRecord'], resolve),
			},
			{
				path: '/incomeDetailed',
				name: 'IncomeDetailed',
				meta: {
					title: '收益明细',
				},
				component: (resolve) =>
					require(['@/views/income/IncomeDetailed'], resolve),
			},
			{
				path: '/rechargeRecord',
				name: 'RechargeRecord',
				meta: {
					title: '充值记录',
				},
				component: (resolve) =>
					require(['@/views/order/RechargeRecord'], resolve),
			},
			{
				path: '/withdrawRecord',
				name: 'WithdrawRecord',
				meta: {
					title: '提现记录',
				},
				component: (resolve) =>
					require(['@/views/order/WithdrawRecord'], resolve),
			},
			{
				path: '/withdraw',
				name: 'WithdrawView',
				meta: {
					title: '收益提现',
				},
				component: (resolve) =>
					require(['@/views/income/WithdrawView'], resolve),
			},
			//h5 修改密码
			{
				path: '/resetPass',
				name: 'ResetPass',
				meta: {
					title: '收益提现',
				},
				component: (resolve) =>
					require(['@/views/login/ResetPass'], resolve),
			},
			{
				path: '/inviteOrder',
				name: 'InviteOrder',
				meta: {
					title: '邀请用户订购记录',
				},
				component: (resolve) =>
					require(['@/views/order/InviteOrder'], resolve),
			},
			{
				path: '/courseList',
				name: 'CourseList',
				meta: {
					title: '课程列表',
				},
				component: (resolve) =>
					require(['@/views/course/CourseList'], resolve),
			},
			{
				path: '/courseDetailed',
				name: 'CourseDetailed',
				meta: {
					title: '课程详情',
					activePath: '/courseList',
				},
				component: (resolve) =>
					require(['@/views/course/CourseDetailed'], resolve),
			},
			...other,
		],
	},
]

const router = new VueRouter({
	mode: 'hash',
	base: process.env.BASE_URL,
	routes,
})
/*const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {undefined
  return originalPush.call(this, location).catch(err => err)
}*/

export default router
