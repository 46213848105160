import axios from 'axios'
import Cookies from 'js-cookie'
import { Message } from 'element-ui'
import store from '@/store'
import router from '@/router'
import { api_url } from './common'

// 创建axios实例
const service = axios.create({
	baseURL: api_url,
	timeout: 60000, // 请求超时时间
})

// request拦截器
service.interceptors.request.use(
	(config) => {
		console.log(config.url)
		if (
			config.url == 'home/buy/getSetMeal' ||
			config.url == 'home/buy/checkWangWangId' ||
			config.url == 'home/buy/payOrder' ||
			config.url == 'home/buy/checkAccountQuery'
		) {
			if (localStorage.getItem('inCode')) {
				config.headers['inCode'] = localStorage.getItem('inCode')
			} else {
				// config.headers['inCode'] = localStorage.getItem('normalCode')
				config.headers['inCode'] = 'xuyx6cbz0lf92lrvvuypd0dmwcp89tdd'
			}
		} else {
			if (Cookies.get('mb_TOKEN')) {
				config.headers['token'] = Cookies.get('mb_TOKEN')
				// 让每个请求携带自定义token 请根据实际情况自行修改
			}
		}

		return config
	},
	(error) => {
		// Do something with request error

		Promise.reject(error)
	}
)
// response 拦截器
service.interceptors.response.use(
	//	 response => response,
	(response) => {
		const res = response.data

		if (res.code !== 200) {
			resetMessage({
				message: res.msg,
				type: 'warning',
			})
			// 判断token值，对各种状态进行处理
			//未登录、过期：状态码 505
			if (res.code == 505) {
				Cookies.remove('mb_TOKEN')
				router.push({
					path: '/login',
					query: {
						redirect: router.currentRoute.fullPath,
					},
				})
			}
		}
		return response.data
	},
	(error) => {
		Message({
			message: error,
			type: 'warning',
		})
		return Promise.reject(error)
	}
)

//防止弹出多个Message
let messageInstance = null
const resetMessage = (options) => {
	if (messageInstance) {
		messageInstance.close()
	}
	messageInstance = Message(options)
}
;['error', 'success', 'info', 'warning'].forEach((type) => {
	resetMessage[type] = (options) => {
		if (typeof options === 'string') {
			options = {
				message: options,
			}
		}
		options.type = type
		return resetMessage(options)
	}
})

export default service
