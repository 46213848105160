<template>
	<router-view />
</template>

<script>
export default {
	methods: {
		setRem() {
			var scale = ''
			if (document.documentElement.clientWidth <= 750) {
				scale = document.documentElement.clientWidth / 37.5
				document.documentElement.style.fontSize = scale + 'px'
				this.$store.commit('ISMOBILECSS', true)
			} else {
				this.$store.commit('ISMOBILECSS', false)
			}
		},
	},
	mounted() {
		// 初始化
		this.setRem()
		var that = this
		window.addEventListener(
			'resize',
			() => {
				that.setRem()
			},
			false
		)
		window.addEventListener(
			'orientationchange',
			() => {
				that.setRem()
			},
			false
		) //移动端
	},
}
</script>

<style>
[class*=' el-icon-lx'],
[class^='el-icon-lx'] {
	font-family: lx-iconfont !important;
}
* {
	margin: 0;
	padding: 0;
}

html,
body,
#app,
.wrapper {
	width: 100%;
	height: 100%;
}

body {
	font-family: 'PingFang SC', 'Helvetica Neue', Helvetica, 'microsoft yahei',
		arial, STHeiTi, sans-serif;
}

a {
	text-decoration: none;
}
</style>
