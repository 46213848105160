export default [
    {
        path: '/withdraw_sure',
        name: 'Withdraw_sure',
        meta: {
            title: '提现成功',
            activePath:"/withdraw"
        },
        component: resolve => require(['@/views/income/Withdraw_sure'], resolve)
    },
    {
        path: '/upgradeAgent_sure',
        name: 'UpgradeAgent_sure',
        meta: {
            title: '升级代理',
            activePath:"/upgradeAgent"
        },
        component: resolve => require(['@/views/person/UpgradeAgent_sure'], resolve)
    },
    {
        path: '/accountRecharge_sure',
        name: 'AccountRecharge_sure',
        meta: {
            title: '账号充值',
            activePath:"/accountRecharge"
        },
        component: resolve => require(['@/views/money/AccountRecharge_sure'], resolve)
    },
    {
        path: '/service_sure',
        name: 'Service_sure',
        meta: {
            title: '服务订购',
            activePath:"/orderService"
        },
        component: resolve => require(['@/views/service/Service_sure'], resolve)
    },
    //邀请链接-H5
    {
        path: '/inviteLink',
        name: 'InviteLink',
        meta: {
            title: '获取邀请链接',
            activePath:"/inviteUsers"
        },
        component: resolve => require(['@/views/person/InviteH5'], resolve)
    },
    {
        path: '/noticeList',
        name: 'NoticeList',
        meta: {
            title: '公告列表',
            activePath:"/noticeList"
        },
        component: resolve => require(['@/views/notice/NoticeList'], resolve)
    },

];
